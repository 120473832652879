<template>
    <Form @submit="onSubmit" v-slot="{ isSubmitting }">
        <div class="form-fields-wrapper">
            <TextField
                v-model="email"
                name="email"
                type="email"
                icon="person"
                iconFilled
                :for="forProp('email')"
                :label="translate.email"
                :errors="errors"
                :placeholder="translate.email"
                autofocus
            />

            <TextField
                v-model="password"
                name="password"
                type="password"
                icon="key"
                iconFilled
                :for="forProp('password')"
                :label="translate.password"
                :errors="errors"
                :placeholder="translate.password"
                required
            >
                <template #labelOptional>
                    <a href="/forgot">{{ translate.forgot_password }}</a>
                </template>
            </TextField>

            <Checkbox
                v-model="rememberMe"
                name="rememberMe"
                id="remember-me"
                :label="translate.remember_me"
            />
        </div>

        <Button
            class="guest-submit"
            icon="login"
            :isSubmitting="isSubmitting"
            :disabled="isSubmitting"
        >
            {{ translate.sign_in }}
        </Button>
    </Form>
</template>

<script setup>
    import { Form, useForm } from "vee-validate";
    import { object, string } from "yup";

    import { handleAuthFormSubmit } from "@/api.js";

    import TextField from "@/vue/components/form-ui/TextField.vue";
    import Checkbox from "@/vue/components/form-ui/Checkbox.vue";
    import Button from "@/vue/components/form-ui/Button.vue";

    const translate = window.TRANSLATE;

    const schema = object({
        email: string()
            .email("Invalid email address")
            .required("Email is required"),
        password: string().required("Password is required"),
    });

    const { defineField, handleSubmit, errors, setErrors } = useForm({
        validationSchema: schema,
    });

    const [email] = defineField("email", {
        validateOnModelUpdate: false,
    });
    const [password] = defineField("password", {
        validateOnModelUpdate: false,
    });
    const [rememberMe] = defineField("rememberMe", {
        validateOnModelUpdate: false,
    });

    const forProp = (fieldName) => `for-${fieldName}`;

    const onSubmit = handleSubmit((values) =>
        handleAuthFormSubmit({
            url: "/login",
            values: values,
            setErrors: setErrors,
        }),
    );
</script>
