import { createApp } from "vue";

import { fadeOutInElements } from "@/utils.js";

import LoginForm from "./LoginForm.vue";

const app = createApp(LoginForm);

app.mount("#login-form");

fadeOutInElements({
    elementToHideId: "login-form-skeleton",
    elementToShowId: "login-form",
});
